import React, { useState, useCallback, useEffect, useRef } from "react";
import { graphql, navigate } from "gatsby";
import { RichText, Date } from "prismic-reactjs";
import Layout from "../components/layout/index";
import { queryPosts } from "../utils/pismicQuery";
import Categories from "../components/Listing/Categories";
import SEO from "../components/seo";
import {
  Skeleton,
  SeeMoreButton,
  StyledLink,
  ImageContainer,
  CardWrapper,
  LoadCard,
} from "../components/Style/BlogCss";
import NoFound from "../components/Blog/NoFound";
import { Height } from "@material-ui/icons";

const DefaultImage = ({ dataImage, url }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const imgObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach((element) => {
        if (!element.isIntersecting) {
          return;
        } else {
          const src = element.target.getAttribute("data-src");
          element.target.src = src;
          element.target.setAttribute(
            "style",
            "border: 1px solid rgba(64, 87, 109, 0.07); opacity: 1; display: block; width: 100%;  height: 100%;  position: absolute;top: 0; left: 0;  border-radius: 4px;"
          );
          imgObserver.unobserve(element.target);
        }
      });
    });

    if (imageRef.current) imgObserver.observe(imageRef.current);
    return () => {
      imgObserver.disconnect();
    };
  }, []);

  return (
    <ImageContainer onClick={() => navigate(url)}>
      <div className="block-img">
        <div
          style={{
            position: "relative",
            paddingBottom: "50.16%",
            height: 0,
          }}
        >
          <img
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            loading="lazy"
            ref={imageRef}
            data-src={dataImage.primary.image_banner.url}
            alt={dataImage.primary.image_banner.alt}
          />
        </div>
      </div>
    </ImageContainer>
  );
};

const PostSlices = ({ slices, url }) => {
  const dataImage = slices.find(
    (slice) => slice.type === "banner_with_caption"
  );

  return (
    <>
      {dataImage !== undefined && (
        <DefaultImage dataImage={dataImage} url={url} />
      )}
    </>
  );
};

const PostBody = ({ bodyImage, url }) => {
  return <PostSlices slices={bodyImage} url={url} />;
};

const BlogIndex = (props) => {
  const {
    pageContext: { language },
    location: { pathname },
  } = props;

  const [blogPosts, setBlogPosts] = useState([]);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const dummyData = Array.from({ length: 7 });

  const renderMorePosts = useCallback((currentBlogPosts, morePosts) => {
    if (morePosts.edges.length !== 0) {
      const newPosts = morePosts.edges.map((post) => post.node);
      setBlogPosts([...currentBlogPosts, ...newPosts]);
      const lastNewPost = morePosts.edges[morePosts.edges.length - 1];
      setCurrentCursor(lastNewPost.cursor);

      if (!morePosts.pageInfo.hasNextPage) {
        setHasMorePosts(false);
        setCurrentCursor("x");
      }
      setHasMorePosts(morePosts.pageInfo.hasNextPage);
    }
  }, []);

  const loadMorePosts = useCallback(async () => {
    const morePosts = await queryPosts(currentCursor, 6, language);
    renderMorePosts(blogPosts, morePosts.data.allPostBlogs);
  }, [renderMorePosts, blogPosts, currentCursor, language]);

  useEffect(() => {
    const fetchInitialPosts = async () => {
      const initialCursor = null;
      const initialPosts = await queryPosts(initialCursor, 7, language);
      renderMorePosts([], initialPosts.data.allPostBlogs);
    };
    fetchInitialPosts();
  }, [renderMorePosts, language]);

  return (
    <Layout>
      <SEO title="Blog" pathname={pathname} />
      <div
        style={{
          maxWidth: 1280,
          margin: "0 auto",
          padding: "0em 1em",
        }}
      >
        <div
          style={{
            padding: "1em 0.4em",
            marginBottom: "0.5em",
            fontSize: "3rem",
            fontWeight: 200,
            borderBottom: "1px solid #fbb400",
          }}
        >
          <strong style={{ color: "#fbb400" }}>KUU</strong>Blog
        </div>
        <CardWrapper>
          {blogPosts.length > 0 ? (
            blogPosts.map((object, index) => (
              <div
                className={index === 0 ? "span-2" : ""}
                key={object._meta.id}
              >
                <PostBody
                  bodyImage={object.body}
                  url={`/blog/${object._meta.uid}`}
                />

                <div className={index === 0 ? "wrapper-text" : ""}>
                  <Categories
                    categories={object.categories}
                    style={{
                      paddingTop: "0.6em",
                      fontSize: "0.8rem",
                    }}
                  />
                  <StyledLink to={`/blog/${object._meta.uid}`}>
                    {RichText.asText(object.title).length !== 0 &&
                      RichText.asText(object.title)}
                  </StyledLink>

                  <div
                    style={{
                      color: "rgba(117, 117, 117, 1)",
                      padding: "0.3em 0em",
                      fontSize: "0.85rem",
                    }}
                  >
                    <time>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      }).format(Date(object.date))}
                    </time>
                  </div>
                </div>
              </div>
            ))
          ) : currentCursor === null ? (
            <>
              {dummyData.map((item, index) => (
                <LoadCard key={index} className={index === 0 ? "span-2" : ""}>
                  <Skeleton />
                  <div className={index === 0 ? "group" : ""}>
                    <h1 />
                    <p />
                  </div>
                </LoadCard>
              ))}
            </>
          ) : (
            <NoFound />
          )}
          {hasMorePosts && blogPosts.length > 0 && (
            <div
              style={{
                textAlign: "center",
                padding: "1em",
                gridColumn: "span 3",
              }}
            >
              <SeeMoreButton onClick={loadMorePosts}> see more </SeeMoreButton>
            </div>
          )}
        </CardWrapper>
      </div>
    </Layout>
  );
};
export default BlogIndex;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
