import { client } from "./prismicHelper";
import gql from "graphql-tag";

const blogPostsQuery = gql`
  query ($currentCursor: String, $language: String, $itemsPerPage: Int) {
    allPostBlogs(
      after: $currentCursor
      first: $itemsPerPage
      lang: $language
      sortBy: meta_lastPublicationDate_DESC
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          _meta {
            id
            uid
            lang
          }
          title
          date
          categories {
            category {
              ... on Category {
                name
              }
            }
          }
          body {
            ... on PostBlogBodyBanner_with_caption {
              type
              primary {
                image_banner
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const queryPosts = async (currentCursor, itemsPerPage, language) => {
  return client.query({
    query: gql`
      ${blogPostsQuery}
    `,
    variables: { currentCursor, itemsPerPage, language },
  });
};
