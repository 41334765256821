import { PrismicLink } from "apollo-link-prismic";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import fragmentTypes from "./fragmentTypes.json";

const client = new ApolloClient({
  link: PrismicLink({
    uri: `https://${process.env.REPO}.cdn.prismic.io/graphql`,
    accessToken: `${process.env.API_KEY}`,
  }),
  cache: new InMemoryCache({ fragmentTypes }),
});
export { client };
