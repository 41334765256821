import React, { useRef, useEffect } from "react";
import NoContent from "../../images/Gif/NoContent.gif";

const NoFound = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const imgObserver = new IntersectionObserver(
      (entries, imgObserver) => {
        entries.forEach((element) => {
          if (!element.isIntersecting) {
            return;
          } else {
            element.target.style.height = "auto";
            imgObserver.unobserve(element.target);
          }
        });
      },
      { threshold: 0 }
    );

    if (imageRef.current) imgObserver.observe(imageRef.current);
    return () => {
      imgObserver.disconnect();
    };
  }, []);
  return (
    <div
      style={{
        maxWidth: 960,
        width: "100%",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      <img
        loading="lazy"
        width="400"
        height="400"
        style={{
          justifySelf: "center",
          margin: "0 auto",
          maxWidth: "100%",
          border: "none",
          display: "block",
        }}
        ref={imageRef}
        src={NoContent}
        alt=""
      />
      <div
        style={{
          fontSize: "clamp(1rem, 2.5vw, 1rem)",
          fontWeight: 600,
        }}
      >
        Sorry, No article found
      </div>
      <div
        style={{
          fontSize: "clamp(1rem, 2.5vw, 1rem)",
          fontWeight: 300,
        }}
      >
        Blog article, not yet available.
      </div>
    </div>
  );
};
export default NoFound;
